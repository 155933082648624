/*@import '~bootstrap-sass/assets/stylesheets/bootstrap';*/

.wrap {
    
}

.menu {
    display: flex;
}
.menu .principal {
    display: flex;
    justify-content: center;
}
.menu .principal ul {
    display: flex;
    flex-flow: row wrap;
}
.menu .principal ul li {
    list-style: none;
    margin: 0 20px;
}

.menu .login {
    display: flex;
    justify-content: center;
}
.menu .login ul {
    display: flex;
    flex-flow: row wrap;
}
.menu .login ul li {
    list-style: none;
    margin: 0 20px;
}
